<template>
  <div class="ticket-item"
       @mouseenter="mouseenter"
       @mouseleave="mouseleave">
    <div class="ticket-row t_ticket-row--title">
      <div class="ticket-header">
        <span class="ticket-title">
          {{ ticket.title }}
        </span>
        <span v-if="isNewTicket"
              class="chip chip--new">{{$t('pages.ticket.sidebarOut.ticketStatus.new')}}</span>
      </div>
    </div>
    <div class="ticket-row t_ticket-row--status">
      <TicketStatus class="ticket-status"
                    :state="ticket.status"
                    @input="updateStatus" />
    </div>
    <div class="ticket-row t_ticket-row--infos">
      <div class="grid-wrapper">
        <div class="grid-tile">
          <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.date')}}</label>
          <span class="grid-tile__value">{{ formatDate(ticket.dateCreation) }}</span>
        </div>
        <div class="grid-tile">
          <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.author')}}</label>
          <span class="grid-tile__value">{{ ticket.author.firstName }} {{ ticket.author.lastName }}</span>
        </div>
        <div class="grid-tile">
          <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.type')}}</label>
          <span class="grid-tile__value">{{$t(`pages.ticket.creationEdition.types.${ticket.type}`)}}</span>
        </div>
        <div class="grid-tile">
          <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.labels.priority')}}</label>
          <span class="grid-tile__value">{{$t(`pages.ticket.creationEdition.priorities.${ticket.priority}`)}}</span>
        </div>
        <div class="grid-tile">
          <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.location')}}</label>
          <span v-if="ticketBuilding && !selectedBuilding"
                class="grid-tile__value">{{ticketBuilding.name}}</span>
          <span v-if="ticketFloor"
                class="grid-tile__value">{{ticketFloor.name}}</span>
          <span v-if="ticketSpace"
                class="grid-tile__value">{{ticketSpace.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import ColorsMixin from '@/app/mixins/colors';
import TicketStatus from '@/app/pages/ticket/components/ticket-status.vue';

export default {
  name: 'TicketItem',
  components: { TicketStatus },
  mixins: [ColorsMixin],
  props: {
    ticket: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      spaceOldColor: null,
      floorOldColor: null,
    };
  },
  computed: {
    ticketStatus() {
      return this.$store.state.tickets.ticketStatus;
    },
    isNewTicket() {
      return moment().diff(moment(this.ticket.dateCreation), 'hours') <= 48;
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    ticketBuilding() {
      if (!this.ticket.buildingUuid) return null;
      return this.$store.state.building.collection.find(b => b.uuid === this.ticket.buildingUuid) || null;
    },
    ticketFloor() {
      if (!this.ticketBuilding || !this.ticket.floorUuid) return null;
      return this.ticketBuilding.next.find(f => f.uuid === this.ticket.floorUuid) || null;
    },
    ticketSpace() {
      if (!this.ticketFloor || !this.ticket.spaceUuid) return null;
      return this.ticketFloor.next.find(s => s.uuid === this.ticket.spaceUuid) || null;
    },
  },
  beforeDestroy() {
    this.unHoverTicketDot();
    if (!this.selectedBuilding) return;
    if (!this.ticket.x && !this.ticket.y && this.ticket.spaceUuid) this.unColorSpace();
    if (!this.ticket.spaceUuid && this.ticket.floorUuid) this.unColorFloor();
  },
  methods: {
    formatDate(date) {
      return moment(date)
        .format('D MMM YYYY, kk:mm')
        .toString();
    },
    mouseenter() {
      this.hoverTicketDot();
      if (!this.selectedBuilding) return;
      if (!this.ticket.x && !this.ticket.y && this.ticket.spaceUuid) this.colorSpace();
      if (!this.ticket.spaceUuid && this.ticket.floorUuid) this.colorFloor();
    },
    mouseleave() {
      this.unHoverTicketDot();
      if (!this.selectedBuilding) return;
      if (!this.ticket.x && !this.ticket.y && this.ticket.spaceUuid) this.unColorSpace();
      if (!this.ticket.spaceUuid && this.ticket.floorUuid) this.unColorFloor();
    },
    hoverTicketDot() {
      const device = document.getElementById(this.ticket.shortId);
      if (device) {
        device.classList.add('device--big');
      }
    },
    unHoverTicketDot() {
      const device = document.getElementById(this.ticket.shortId);
      if (device) device.classList.remove('device--big');
    },
    colorSpace() {
      const space = this.$palmier.getSpace(this.ticket.spaceUuid);
      if (space) {
        this.spaceOldColor = space.style.fill;
        space.style.fill = this.colors.primary;
      }
    },
    colorFloor() {
      const spaces = this.ticketFloor.next.map(s => s.uuid);
      const space = this.$palmier.getSpace(spaces[0]);
      this.floorOldColor = space.style.fill;
      const hoveredZone = this.$palmier.zone(spaces);
      hoveredZone.apply({
        style: s => (s.fill = this.colors.primary),
      });
    },
    unColorSpace() {
      const space = this.$palmier.getSpace(this.ticket.spaceUuid);
      if (space) space.style.fill = this.spaceOldColor;
    },
    unColorFloor() {
      const spaces = this.ticketFloor.next.map(s => s.uuid);
      const hoveredZone = this.$palmier.zone(spaces);
      hoveredZone.apply({
        style: s => (s.fill = this.floorOldColor),
      });
    },
    async updateStatus(status) {
      try {
        await this.$store.dispatch('tickets/updatePrivate', { shortId: this.ticket.shortId, status });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || this.$t('pages.ticket.toasts.errorUpdatingTicketStatus'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$status-border-size = 4px

.ticket-item
  display flex
  flex-direction column
  margin-bottom 8px
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-card
  transition box-shadow 100ms ease
  &:hover
    background-color #ebecf0
  .ticket-row
    display flex
  .ticket-header
    display flex
    align-items flex-start
    padding $space-2
    width 100%
    border-bottom $grid-border
    .ticket-title
      flex 2
      text-align left
      font-weight 400
      font-size $fs-h1
    .chip
      margin-left $space-2
  .ticket-status
    padding $space-2

.chip
  padding 4px
  border-radius 2px
  letter-spacing 0.5px
  line-height 0.9
  &--new
    background-color $warning
    color $dark
</style>
