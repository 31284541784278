<template>
  <div :class="sidebarClass"
       class="sidebar-extensible">
    <div class="sidebar-extensible__main">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarExtensible',
  model: {
    prop: 'size',
  },
  props: {
    size: {
      type: String,
      default: 'small',
      validator: v => ['hidden', 'small', 'medium', 'large'].includes(v),
    },
  },
  computed: {
    sidebarClass() {
      return [`sidebar-extensible--${this.size}`];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.sidebar-extensible
  position absolute
  top 0
  right 0
  bottom 0
  z-index 10
  display flex
  flex-direction column
  width 350px
  height 100%
  transition all 0.3s ease
  &--hidden
    right -350px
  &--small
    width 350px
  &--medium
    width calc((100vw / 3))
  &--large
    width calc(100vw - 350px)
  .sidebar-extensible__main
    flex 2
    overflow-x hidden
    overflow-y hidden
    background-color $light

::-webkit-scrollbar
  width 6px

::-webkit-scrollbar-track
  background #cacaca

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #000
</style>
