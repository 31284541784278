import DebounceMixin from '@/app/mixins/debounce';

export default {
  mixins: [DebounceMixin],
  data() {
    return {
      floorDrawerState: {},
    };
  },
  computed: {
    selectInFilters() {
      return this.$store.getters['selections/selectInFilters'];
    },
    buildings() {
      return this.$store.state.building.collection;
    },
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
  },
  methods: {
    isBuildingSelected(building) {
      return building.uuid in this.filteredSelection;
    },
    floorIsSelected(building, floorUuid) {
      if (!building) return false;
      return floorUuid in building;
    },
    isWholeBuildingSelected(buildingUuid) {
      if (!(buildingUuid in this.filteredSelection)) return false;
      const curBuilding = this.buildings.find(b => b.uuid === buildingUuid);
      let res = true;
      curBuilding.next.forEach(f => {
        if (
          !this.floorIsSelected(this.filteredSelection[buildingUuid], f.uuid) ||
          this.filteredSelection[buildingUuid][f.uuid].length !== 0
        )
          res = false;
      });
      return res;
    },
    toggleBuildingSelect(building) {
      if (this.isBuildingSelected(building)) {
        this.$store.commit('selections/unselectBuilding', { building: building.uuid });
      } else {
        this.$store.commit('selections/selectBuilding', { building: building.uuid });
        this.addAllFloorsToSelection(building);
      }
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    toggleFloorSelectionDrawer(building) {
      const currentState = this.floorDrawerState[building.uuid];
      this.resetFloorDrawersState();
      this.$set(this.floorDrawerState, building.uuid, !currentState);
    },
    addAllFloorsToSelection(building) {
      building.next.forEach(f => {
        const floorInFilters = this.selectInFilters({ building: building.uuid, floor: f.uuid });
        if (floorInFilters && floorInFilters.length !== f.next.length) {
          floorInFilters.forEach(s => {
            this.$store.commit('selections/selectBuilding', { building: building.uuid, floor: f.uuid, space: s });
          });
        } else this.$store.commit('selections/selectBuilding', { building: building.uuid, floor: f.uuid });
      });
      if (!this.filteredSelection[building.uuid] || !Object.keys(this.filteredSelection[building.uuid]).length)
        this.$store.commit('selections/unselectBuilding', { building: building.uuid });
    },
    getFloorPercentageSelection(building) {
      const currentFloors = this.filteredSelection[building.uuid];
      if (!currentFloors) return;
      const floorCount = building.next.length;
      const floorSelected = Object.keys(currentFloors).length;
      const percentageSelected = (floorSelected / floorCount) * 100;
      return `${percentageSelected.toFixed(0)}%`;
    },
    resetFloorDrawersState() {
      Object.keys(this.buildings).forEach(k => {
        const buildingUuid = this.buildings[k].uuid;
        this.$set(this.floorDrawerState, buildingUuid, false);
      });
    },
  },
};
