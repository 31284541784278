<template>
  <div>
    <div class="buildings"
         @wheel="transformScroll">
      <div v-if="fetchingBuildingCollection"
           class="spinner-container">
          <Spinner class="spinner"/>
      </div>
      <div v-if="!fetchingBuildingCollection && buildings && buildings.length"
           id="buildings-scrollable"
           :class="buildingContainerClass"
           class="buildings-container buildings-container--card">
        <BuildingCard v-for="building in buildings"
                      :key="building.uuid"
                      :building="building"
                      :buildingShape="building.metadata.concave_hull"
                      :limits="limits"
                      :tickets="tickets"
                      @rightClick="rightClick(building)" />
      </div>
      <div v-if="!fetchingBuildingCollection && (!buildings || !buildings.length)"
           class="no-buildings">
        <span class="no-buildings__message"
              style="font-weight:700">
          You don't have any building
        </span>
        <span class="no-buildings__message">
          Do you want to
          <q-btn label="Add a building"
                 class="no-buildings__button"
                 color="black"
                 @click="showUploadModal = true" /> ?
        </span>
      </div>
      <UploadModal :show="showUploadModal"
                   @close="showUploadModal = false" />
    </div>
  </div>
</template>

<script>
import BuildingCard from '@/app/components/buildings/building-card.vue';
import UploadModal from '@/app/views/ifc-upload/modal.vue';
import Spinner from '@/app/components/ui/spinner.vue';


export default {
  name: 'Buildings',
  components: {
    BuildingCard,
    Spinner,
    UploadModal,
  },
  props: {
    tickets: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      showUploadModal: false,
    };
  },
  computed: {
    buildings() {
      return this.$store.state.building.collection;
    },
    fetchingBuildingCollection() {
      return this.$store.state.building.fetching;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    fetching() {
      return this.$store.state.oapp.fetching;
    },

    buildingContainerClass() {
      return {
        'buildings-container--center': this.buildings.length < 5,
      };
    },
    limits() {
      if (!this.currentOapp || !this.currentOapp.data || !this.currentOapp.type) return { min: 0, max: 0 };
      return {
        min: this.currentOapp.limits[this.currentOapp.type].min,
        max: this.currentOapp.limits[this.currentOapp.type].max,
      };
    },
  },
  methods: {
    rightClick(building) {
      this.$emit('rightClick', { buildingUuid: building.uuid });
    },
    transformScroll(event) {
      if (!event.deltaY) {
        return;
      }
      const element = document.getElementById('buildings-scrollable');
      element.scrollLeft += event.deltaY + event.deltaX;
      event.preventDefault();
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.buildings
  display flex
  justify-content center
  height 100%
  background-color $silver
  @media screen and (max-width: 500px)
    margin-bottom 70px
  .spinner-container
    display flex
    justify-content center
    align-items center
    height 100%
    .spinner
      margin-top 100px
  .buildings-container
    display flex
    align-items center
    overflow-x auto
    height 100%
    &--center
      justify-content center
  .buildings-container--card
    padding $space-3
    @media screen and (max-width: 500px)
      padding 0 0 80px 0

.no-buildings
  display flex
  flex-direction column
  justify-content center
  height 100%
  .no-buildings__message
    padding 15px
    width 100%
    color black
    text-align center
    font-size $fs-h1
  .no-buildings__button
    margin 0 5px
</style>
