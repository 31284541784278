<template>
  <div class="tickets-container">
    <span class="sidebar-title">
      {{$t('pages.ticket.sidebarOut.title')}}
    </span>
    <div class="action-container">
      <button v-if="selectedBuilding && user.featuresRights.ticket.WRITE"
              class="create-ticket o-button o-button--squared o-button--hover o-button--shadows o-button--primary-gradient"
              @click="ticketEdition">
        {{$t('pages.ticket.sidebarOut.buttons.createNewTicket')}}
      </button>
      <div class="list-filters">
        <button :class="filterIsSelected('all')"
                class="filter-item t_filter-item--all"
                @click="statusFilter = 'all'">{{$t('pages.ticket.sidebarOut.ticketStatus.all')}}</button>
        <button :class="filterIsSelected('todo')"
                class="filter-item t_filter-item--todo"
                @click="statusFilter = 'todo'">{{$t('pages.ticket.sidebarOut.ticketStatus.todo')}}</button>
        <button :class="filterIsSelected('in progress')"
                class="filter-item t_filter-item--inProgress"
                @click="statusFilter = 'in progress'">{{$t('pages.ticket.sidebarOut.ticketStatus.inProgress')}}</button>
        <button :class="filterIsSelected('done')"
                class="filter-item t_filter-item--done"
                @click="statusFilter = 'done'">{{$t('pages.ticket.sidebarOut.ticketStatus.done')}}</button>
      </div>
    </div>
    <span v-if="noTickets && !selectedBuilding && user.featuresRights.ticket.WRITE"
          class="message-display-container">
      {{$t('pages.ticket.sidebarOut.messages.createTicketOnBuilding')}}
    </span>
    <span v-else-if="noTickets && selectedBuilding && user.featuresRights.ticket.WRITE"
          class="message-display-container">
      {{$t('pages.ticket.sidebarOut.messages.createTicketOnSpace')}}
    </span>
    <div v-else-if="user.featuresRights.ticket.READ"
         class="tickets-list">
      <ticket-item v-for="ticket in filteredTickets"
                   :key="ticket.shortId"
                   :ticket="ticket"
                   @click.native="setCurrentTicket(ticket)" />
    </div>
    <span v-else
          class="message-display-container">
      {{$t('pages.ticket.sidebarOut.messages.noTicket')}}
    </span>
  </div>
</template>

<script>
import TicketItem from '@/app/pages/ticket/components/ticket-item.vue';

export default {
  name: 'SidebarTickets',
  components: {
    TicketItem,
  },
  data() {
    return {
      sidebarSize: 'medium',
      statusFilter: 'all',
    };
  },
  computed: {
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    sortedTickets() {
      const sorted = this.$store.state.tickets.collection;
      sorted.sort((a, b) => (a.dateCreation < b.dateCreation ? 1 : b.dateCreation < a.dateCreation ? -1 : 0));
      if (this.selectedBuilding) return sorted.filter(t => t.buildingUuid === this.selectedBuilding.uuid);
      return sorted;
    },
    filteredTickets() {
      if (this.statusFilter === 'all') return this.sortedTickets;
      return this.sortedTickets.filter(t => t.status === this.statusFilter);
    },
    noTickets() {
      return this.filteredTickets.length === 0;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    filterIsSelected(state) {
      return {
        isSelected: state === this.statusFilter,
      };
    },
    ticketEdition() {
      this.$emit('startEdition');
    },
    setCurrentTicket(ticket) {
      this.$store.dispatch('tickets/readPrivate', ticket.shortId);
      this.$store.commit('tickets/setCurrentTicket', ticket.shortId);
      this.ticketEdition();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.tickets-container
  display flex
  flex-direction column
  height 100%
  .sidebar-title
    display flex
    align-items center
    padding $space-2
    color $font-color
    text-transform uppercase
    font-weight 700
    font-size 2rem
  .create-ticket
    width fit-content
    text-transform uppercase
  .message-display-container
    margin-top $space-4
    padding $space-3
    text-align center
    font-weight 300
    font-size $fs-big
  .tickets-list
    overflow auto
    padding 0 $space-2
    height 100%
    text-align center
    .ticket-item:last-child
      margin-bottom $space-3
  .action-container
    display flex
    align-items center
    padding 0 $space-2 $space-2

.list-filters
  margin-left auto
  .filter-item
    text-transform uppercase
  button
    outline none
    border none
    background none
    letter-spacing 0.5px
    font-weight 400
    font-size $fs-body
    cursor pointer
    &:hover
      text-decoration underline
  .isSelected
    text-decoration underline
    font-weight 900
</style>
