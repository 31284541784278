<template>
  <div class="floors-selection">
    <span class="floors-selection__title">
      {{$t('components.floorSelection.floors')}}
    </span>
    <div class="floors-list">
      <FloorCheckbar :selected="allFloorSelected"
                     :name="$t('components.floorSelection.all')"
                     @click="toggleSelectAll" />
      <FloorCheckbar v-for="floor in floors"
                     :key="floor.uuid"
                     :selected="floorIsSelected(floor.uuid)"
                     :name="floor.name"
                     class="floor-item"
                     @click="toggleFloorSelect(floor)" />
    </div>
  </div>
</template>

<script>
import DebounceMixin from '@/app/mixins/debounce';
import FloorCheckbar from '@/app/components/floors/floor-checkbar.vue';

export default {
  name: 'FloorSelection',
  components: {
    FloorCheckbar,
  },
  mixins: [DebounceMixin],
  props: {
    floors: {
      type: Array,
      default: () => [],
    },
    buildingUuid: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectInFilters() {
      return this.$store.getters['selections/selectInFilters'];
    },
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    floorSelection() {
      return this.filteredSelection[this.buildingUuid];
    },
    allFloorSelected() {
      for (const idx in this.floors)
        if (this.floors[idx].whitelist.length > 0 && !this.floorIsSelected(this.floors[idx].uuid)) return false;
      return true;
    },
  },
  methods: {
    floorIsSelected(floorUuid) {
      return this.floorSelection ? floorUuid in this.floorSelection : false;
    },
    toggleSelectAll() {
      let selectionFunction = selection => this.$store.commit('selections/selectBuilding', selection);
      if (this.allFloorSelected) {
        selectionFunction = selection => this.$store.commit('selections/unselectBuilding', selection);
      }
      this.floors.forEach(f => {
        if (f.whitelist.length === 0) return;
        if (!this.allFloorSelected) {
          const floorInFilters = this.selectInFilters({ building: this.buildingUuid, floor: f.uuid });
          if (floorInFilters && floorInFilters.length !== f.next.length) {
            floorInFilters.forEach(s => {
              selectionFunction({ building: this.buildingUuid, floor: f.uuid, space: s });
            });
          } else selectionFunction({ building: this.buildingUuid, floor: f.uuid });
        } else selectionFunction({ building: this.buildingUuid, floor: f.uuid });
      });
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    toggleFloorSelect(floor) {
      const selection = { building: this.buildingUuid, floor: floor.uuid };
      if (floor.whitelist.length === 0) return;
      if (this.floorIsSelected(floor.uuid)) {
        this.$store.commit('selections/unselectBuilding', selection);
      } else {
        const floorInFilters = this.selectInFilters(selection);
        if (floorInFilters && floorInFilters.length !== floor.next.length) {
          floorInFilters.forEach(s => {
            this.$store.commit('selections/selectBuilding', { building: this.buildingUuid, floor: floor.uuid, space: s });
          });
        } else this.$store.commit('selections/selectBuilding', selection);
      }
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.floors-selection
  display flex
  flex-direction column
  .floors-selection__title
    margin-bottom 8px
    margin-left 20px
    padding 20px 20px 8px 0
    border-bottom $border-light solid $grey
    text-transform uppercase
    font-weight 400
    font-size $fs-h1
  .floors-list
    padding 20px
    .floor-item
      margin-bottom 2px
      &:last-child
        margin-bottom 0
</style>
