<template>
  <div class="nav-info">
    <div v-if="hasNavigationSlots"
         :class="navigationItemListClass"
         class="navigation-item-list">
      <slot name="navigation" />
      <NavItem v-if="hasInfoSlots"
               onLeft
               @click="toggleInfoCollapseState">
        <ChevronPicto size="10px"
                      class="collapse-arrow" />
      </NavItem>
      <slot name="navigationRight" />
    </div>
    <CollapseContainer :pose="infoCollapsed ? 'hidden' : 'visible'">
      <div v-if="hasInfoSlots"
           class="info-item-list">
        <slot name="info" />
      </div>
    </CollapseContainer>
  </div>
</template>

<script>
import posed from 'vue-pose';

import NavItem from '@/app/components/layout/nav-info/components/navigation-item.vue';
import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';
export default {
  name: 'NavInfo',
  components: {
    NavItem,
    ChevronPicto,
    CollapseContainer: posed.div({
      visible: {
        height: 'auto',
        applyAtEnd: { overflow: 'visible' },
      },
      hidden: {
        height: '0px',
        applyAtStart: { overflow: 'hidden' },
      },
    }),
  },
  data() {
    return {
      infoCollapsed: false,
    };
  },
  computed: {
    hasNavigationSlots() {
      return 'navigation' in this.$slots && this.$slots.navigation.length !== 0;
    },
    hasInfoSlots() {
      return 'info' in this.$slots && this.$slots.info.length !== 0;
    },
    //computed classes
    navigationItemListClass() {
      return {
        'navigation-item-list--separator': this.hasInfoSlots && !this.infoCollapsed,
      };
    },
  },
  methods: {
    toggleInfoCollapseState() {
      this.infoCollapsed = !this.infoCollapsed;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.nav-info
  border $border-light solid $light
  .navigation-item-list, .info-item-list
    display flex
  .navigation-item-list
    &--separator
      border-bottom $border-light solid $light
</style>