<template>
  <div class="floor-checkbar"
       @click="emitClick"
  >
    <div :class="checkboxClass"
         class="checkbox"
    />
    <span class="floor-name">
      {{ name }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'FloorCheckbar',
  props: {
    name: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    checkboxClass() {
      return {
        'checkbox--checked': this.selected,
      };
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.floor-checkbar
  position relative
  display flex
  box-sizing border-box
  padding 4px 0
  .floor-name
    flex 2
    font-size $fs-h3
.checkbox
  display flex
  margin-right 16px
  padding 3px
  width 20px
  height @width
  background-color $dark
  cursor pointer
  transform rotateX(-60deg) rotateY(0deg) rotateZ(-45deg)
  &--checked
    background-color white
</style>
