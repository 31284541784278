<template>
  <div class="tickets-stats">
    <div class="stat">
      <span class="label label--new">
        {{$t('pages.ticket.sidebarOut.ticketStatus.new')}}
      </span>
      <span class="value">
        {{ newCount }}
      </span>
    </div>
    <div class="stat">
      <span class="label label--todo">
        {{$t('pages.ticket.sidebarOut.ticketStatus.todo')}}
      </span>
      <span class="value">
        {{ todoCount }}
      </span>
    </div>
    <div class="stat">
      <span class="label label--progress">
        {{$t('pages.ticket.sidebarOut.ticketStatus.inProgress')}}
      </span>
      <span class="value">
        {{ inProgressCount }}
      </span>
    </div>
    <div class="stat">
      <span class="label label--done">
        {{$t('pages.ticket.sidebarOut.ticketStatus.done')}}
      </span>
      <span class="value">
        {{ doneCount }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TicketItem',
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    todoCount() {
      return this.tickets.filter(t => t.status === 'todo').length;
    },
    newCount() {
      return this.tickets.filter(t => moment().diff(moment(t.dateCreation), 'hours') <= 48).length;
    },
    inProgressCount() {
      return this.tickets.filter(t => t.status === 'in progress').length;
    },
    doneCount() {
      return this.tickets.filter(t => t.status === 'done').length;
    },
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.tickets-stats
  display flex
  justify-content space-evenly
  border-radius 30px
  background-color black
  color white
  .stat
    display flex
    flex-direction column
    padding 5px
    width 100%
    border-right solid 1px $dark
    text-align center
    &:last-child
      border-right none
    .label
      text-transform uppercase
      font-weight 600
      font-size $fs-body
      &--new
        color lighten(yellow, 30%)
      &--todo
        color lighten(blue, 60%)
      &--progress
        color lighten(orange, 20%)
      &--done
        color lighten(green, 70%)
    .value
      font-size $fs-h1
</style>
