<template>
  <div class="building-card o-card">
    <div class="building-name grid-wrapper">
      {{ building.name }}
    </div>
    <div @click="goToBuilding(building)">
      <building3D :buildingShape="buildingShape"
                  :preferences="building.metadata.preferences"
                  :buildingUUID="building.uuid"
                  :colors="buildingColors"
                  :vHeight="$q.platform.is.mobile ? 100 :200"
                  :vWidth="$q.platform.is.mobile ? 125: 250"
                  class="building-3d"
                  @rightClick="rightClick()"
                  @click="goToBuilding(building)" />
    </div>
    <div v-if="!tickets"
         class="checkbar-container grid-wrapper">
      <BuildingCheckbar :building="building"
                        :selected="isBuildingSelected(building)"
                        :openFloorSelection="false"
                        noSelection
                        @mainClick="toggleBuildingSelect(building)" />
    </div>
    <span v-if="!tickets && $q.platform.is.desktop"
          class="building__address grid-wrapper">
      <div class="address-container grid-tile">
        {{ buildingAddress }}
      </div>
      <div v-if="user && user.featuresRights.buildingConfig.READ"
           class="configuration-container grid-tile">
        <q-icon name="mdi-settings"
                size="20px"
                color="white"
                class="building__configuration"
                @click.native="goToBuildingConfiguration(building)" />
      </div>
    </span>
    <template v-if="tickets">
      <div v-if="user && user.featuresRights.ticket.WRITE"
           class="button-container grid-wrapper">
        <button class="create-ticket o-button o-button--squared o-button--shadows o-button--hover o-button--primary-gradient"
                @click="rightClick">
          {{$t('pages.ticket.sidebarOut.buttons.createTicket')}}
        </button>
      </div>
      <div class="task-count grid-wrapper">
        <div class="grid-tile grid-tile--new">
          <label class="grid-tile__label">{{$t('pages.ticket.sidebarOut.ticketStatus.new')}}</label>
          <span class="grid-tile__value">
            {{ newTicketsCount }}
          </span>
        </div>
        <div class="grid-tile t_grid-tile--todo">
          <label class="grid-tile__label">{{$t('pages.ticket.sidebarOut.ticketStatus.todo')}}</label>
          <span class="grid-tile__value">
            {{ todoTicketsCount }}
          </span>
        </div>
      </div>
      <div class="task-count grid-wrapper">
        <div class="grid-tile t_grid-tile--inProgress">
          <label class="grid-tile__label">
            {{$t('pages.ticket.sidebarOut.ticketStatus.inProgress')}}
          </label>
          <span class="grid-tile__value">
            {{progressTicketsCount}}
          </span>
        </div>
        <div class="grid-tile t_grid-tile--done">
          <label class="grid-tile__label">{{$t('pages.ticket.sidebarOut.ticketStatus.done')}}</label>
          <span class="grid-tile__value">
            {{ doneTicketsCount }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import chroma from 'chroma-js';
import moment from 'moment';
import { mapState, mapGetters, mapMutations } from 'vuex';

import BuildingSelectionMixin from '@/app/mixins/building-selection';
import ColorsMixin from '@/app/mixins/colors';

import BuildingCheckbar from '@/app/components/buildings/building-card-checkbar.vue';
import building3D from '@/app/components/buildings/building3d.vue';

export default {
  name: 'BuildingCard',
  components: {
    BuildingCheckbar,
    building3D,
  },
  mixins: [BuildingSelectionMixin, ColorsMixin],
  props: {
    building: {
      type: Object,
      default: null,
    },
    buildingShape: {
      type: Object,
      default: null,
    },
    tickets: {
      type: Array,
      default: null,
    },
    limits: {
      type: Object,
      default: () => ({ min: 0, max: 0 }),
    },
  },
  data() {
    return {
      colorScale: null,
      buildingColors: [],
    };
  },
  computed: {
    fetching() {
      return this.$store.state.oapp.fetching;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    buildingAddress() {
      return `${this.building.address.line1} ${this.building.address.zipCode} ${this.building.address.city}`;
    },
    ...mapState('ui', ['colorScales']),
    ...mapGetters('oapps', ['getOappFloorsData']),
    currentTimelineCursorDate() {
      return this.$store.state.selections.time.timeline;
    },
    filteredSelection() {
      if (this.$route.name === 'data') {
        return this.$store.getters['selections/filteredBuildings'];
      }
      return {};
    },
    buildings() {
      return this.$store.state.building.collection;
    },
    newTicketsCount() {
      return this.tickets.filter(t => t.buildingUuid === this.building.uuid && moment().diff(moment(t.dateCreation), 'hours') <= 48).length;
    },
    todoTicketsCount() {
      return this.tickets.filter(t => t.buildingUuid === this.building.uuid && t.status === 'todo').length;
    },
    progressTicketsCount() {
      return this.tickets.filter(t => t.buildingUuid === this.building.uuid && t.status === 'in progress').length;
    },
    doneTicketsCount() {
      return this.tickets.filter(t => t.buildingUuid === this.building.uuid && t.status === 'done').length;
    },
    liveMode() {
      return this.$store.state.selections.time.range === 'live';
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    currentTimelineCursorDate() {
      this.createColors();
    },
    fetching(val) {
      if (!val) this.createColors();
    },
    filteredSelection: {
      handler() {
        this.createColors();
      },
      deep: true,
    },
    'currentOapp.id'() {
      this.createColors();
    },
  },
  mounted() {
    this.createColors();
  },
  methods: {
    ...mapMutations('ui', {
      setViewerMode: 'SET_VIEWER_MODE',
    }),
    isEmpty(val) {
      return val === undefined || val === null;
    },
    createColors() {
      if (!this.buildingShape) return; //No mini building
      const list = [];
      const currentBuildingSelected =
        this.filteredSelection && this.filteredSelection[this.building.uuid] ? this.filteredSelection[this.building.uuid] : undefined; //get current building with selection

      let floorsData = null;
      if (this.currentOapp) {
        if (this.liveMode) floorsData = this.currentOapp && this.currentOapp.data ? this.currentOapp.data.GoodBadDetail.floors : null;
        if (this.currentOapp.data && this.currentTimelineCursorDate) {
          if (this.currentOapp.data.GoodBadDetail && this.currentOapp.data.GoodBadDetail[this.currentTimelineCursorDate])
            floorsData = this.currentOapp.data.GoodBadDetail[this.currentTimelineCursorDate].floors;
          else if (this.currentOapp.data.values && this.currentOapp.data.values[this.currentTimelineCursorDate])
            floorsData = this.currentOapp.data.values[this.currentTimelineCursorDate].floors;
          else floorsData = null;
        } else floorsData = null;
      }
      this.createColorScale(); //create color scale with scale from store and limits from oappData

      //for each floor of mini building
      this.buildingShape.contour.forEach((x, idx) => {
        const floorIsSelected = this.floorIsSelected(currentBuildingSelected, x.uuid); //check if floor is selected
        if (this.currentOapp && floorIsSelected)
          if (floorsData && this.colorScale)
            list.push(!this.isEmpty(floorsData[x.uuid]) ? this.colorScale(floorsData[x.uuid]) : chroma(this.colors.grey));
          else list.push(chroma('white'));
        //push color from floorsData created with chroma
        else if (floorIsSelected) list.push(chroma('white'));
        else list.push(null);
      });
      //Change building colors only if their are different from old ones
      if (!this.isSameColors(this.buildingColors, list)) this.buildingColors = list;
    },
    isSameColors(colors1, colors2) {
      if (!colors1 || colors1.length !== colors2.length) return false;
      return !colors1.some((c, idx) => {
        return (c ? c.toString() : c) !== (colors2[idx] ? colors2[idx].toString() : null);
      });
    },
    createColorScale() {
      if (this.colorScales && this.currentOapp && this.currentOapp.type && this.limits)
        this.colorScale = chroma.scale(this.colorScales[this.currentOapp.type]).domain([this.limits.min, this.limits.max]);
    },
    goToBuilding(building) {
      if (_.size(this.filteredSelection) === 0 && !this.tickets) this.toggleBuildingSelect(building);
      if (!this.tickets) this.setViewerMode('navigation');
      this.$store.commit('building/setSelectedBuilding', building);
    },
    rightClick() {
      this.$emit('rightClick');
    },
    goToBuildingConfiguration(building) {
      this.setViewerMode('simple');
      this.$store.commit('building/setSelectedBuilding', building);
      this.$router.push({ name: 'configuration' });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$grid-border-dark = 1px solid $light

.building-card
  display flex
  flex-basis 160px
  flex-direction column
  justify-content center
  align-items center
  margin 8px 4px
  background-color darken($light, 5%)
  @media screen and (max-width: 500px)
    flex-basis 80px
    margin 0px 7px
  .building-checkbar
    width 100%
  .building-3d
    border-top $grid-border-dark
    cursor pointer
  .checkbar-container
    padding $space-2
  .address-container
    flex 2
    border-right none
  .building__address
    display flex
    justify-content flex-start
    align-items center
    font-size $fs-little
    .configuration-container
      width fit-content
      border-left $grid-border-dark
    .building__configuration
      width fit-content
      cursor pointer
  .building-name
    padding $space-2
    font-weight 700
    font-size $fs-h2
  .button-container
    justify-content center
    padding $space-2
  .create-ticket
    text-transform uppercase
    cursor pointer

.task-count
  .grid-tile
    width 50%
  .grid-tile__label
    margin-bottom $space-1
    text-transform uppercase
  .grid-tile__value
    font-weight 700
    font-size $fs-h2
</style>
