<template>
  <button class="navigation-item"
          :class="navigationItemClass"
          @click="emitClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    onLeft: {
      type: Boolean,
      default: false,
    },
    borderLeft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navigationItemClass() {
      return {
        'navigation-item--selected': this.selected,
        'navigation-item--disabled': this.disabled,
        'navigation-item--hover': !this.disabled,
        'navigation-item--left': this.onLeft,
        'navigation-item--border-left': this.borderLeft || this.onLeft,
      };
    },
  },
  methods: {
    emitClick() {
      if (this.disabled) return;
      this.$emit('click');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.navigation-item
  padding $space-1
  outline none
  border unset
  border-right $border-light solid $light
  background-color transparent
  font-weight $fw-light
  font-size $fs-h3
  cursor pointer
  &--hover:hover, &--selected
    background-color $light
  &--disabled
    cursor default
  &--left
    margin-left auto
    border-right unset
  &--border-left
    border-left $border-light solid $light
</style>