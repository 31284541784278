<template>
  <div style="height: 100%">
    <q-layout ref="layout"
              class="building-facts">
      <q-page-container :class="buildingCenterClass"
                        class="building-facts__center">
        <div class="viewer">
          <template v-if="!selectedBuilding">
            <tickets-stats :tickets="tickets"
                           class="tickets-stats" />
            <BuildingsList :tickets="tickets"
                           class="building-list"
                           @rightClick="val => createNewTicket(val)" />
          </template>
          <div v-else
               class="tickets-plan-container">
            <NavInfo>
              <template slot="navigation">
                <NavItem @click="goBack">
                  <ChevronPicto size="10px"
                                class="back-button-arrow" />
                </NavItem>
                <NavItem disabled
                         class="building-name">
                  {{selectedBuilding.name}}
                </NavItem>
              </template>
            </NavInfo>
            <building-viewer :editorMode="false"
                             class="building-viewer"
                             @click="closeCreation"
                             @ticketRightClick="val => createNewTicket(val)" />
          </div>
        </div>
        <SidebarExtensible
          :size="'medium'"
          class="sidebar-out">
          <TicketCreationEdition v-if="edition"
                                 :ticketPosition="ticketPosition"
                                 :currentTicket="currentTicket"
                                 @exitEdition="exitEdition" />
          <div v-else-if="currentTicket"
               class="ticket-view">
            <ChevronPicto size="20px"
                          class="back-button-arrow"
                          color="black"
                          @click.native="closeTicket" />
            <TicketView :ticket="currentTicket"
                      @startEdition="startEdition" />
          </div>
          <SidebarTickets v-else-if="!currentTicket"
                          @startEdition="startEdition" />
        </SidebarExtensible>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ColorsMixin from '@/app/mixins/colors';

import NavInfo from '@/app/components/layout/nav-info';
import NavItem from '@/app/components/layout/nav-info/components/navigation-item.vue';
import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';
import BuildingsList from '@/app/components/buildings/buildings-list.vue';
import BuildingViewer from '@/app/views/building-viewer';
import SidebarTickets from '@/app/pages/ticket/views/sidebar-tickets.vue';
import TicketCreationEdition from '@/app/pages/ticket/views/ticket-creation-edition.vue';
import TicketView from '@/app/pages/ticket/views/ticket-view.vue';
import TicketsStats from '@/app/pages/ticket/components/tickets-stats.vue';
import SidebarExtensible from '@/app/components/layout/sidebar/sidebar-extensible.vue';

export default {
  name: 'Tickets',
  components: {
    TicketCreationEdition,
    SidebarTickets,
    BuildingsList,
    BuildingViewer,
    TicketView,
    TicketsStats,
    NavInfo,
    NavItem,
    ChevronPicto,
    SidebarExtensible,
  },
  mixins: [ColorsMixin],
  data() {
    return {
      edition: false,
      ticketPosition: {},
      currentTicket: null,
    };
  },
  computed: {
    tickets() {
      return this.$store.state.tickets.collection;
    },
    ...mapState('ui', {
      viewerMode: state => state.viewerMode,
    }),
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    user() {
      return this.$store.state.user.user;
    },
    ticketBuilding() {
      if (!this.currentTicket.buildingUuid) return null;
      return this.$store.state.building.collection.find(b => b.uuid === this.currentTicket.buildingUuid) || null;
    },
    ticketFloor() {
      if (!this.ticketBuilding || !this.currentTicket.floorUuid) return null;
      return this.ticketBuilding.next.find(f => f.uuid === this.currentTicket.floorUuid) || null;
    },
    ticketSpace() {
      if (!this.ticketFloor || !this.currentTicket.spaceUuid) return null;
      return this.ticketFloor.next.find(s => s.uuid === this.currentTicket.spaceUuid) || null;
    },
  },
  watch: {
    currentTicket() {
      this.edition = false;
    },
  },
  created() {
    this.setViewerMode('tickets');
  },
  mounted() {
    this.$store.dispatch('tickets/fetch');
  },
  methods: {
    ...mapMutations('ui', { setViewerMode: 'SET_VIEWER_MODE' }),
    goBack() {
      this.$store.commit('building/setSelectedBuilding', null);
    },
    createNewTicket(val) {
      if (!this.user.featuresRights.ticket.WRITE) return;
      this.currentTicket = null;
      this.ticketPosition = val;
      this.edition = true;
    },
    startEdition() {
      this.edition = true;
      this.currentTicket = this.$store.state.tickets.currentTicket;
    },
    exitEdition() {
      this.edition = false;
      this.currentTicket = null;
      this.ticketPosition = {};
    },
    closeTicket() {
      if (this.selectedBuilding) {
        this.unHoverTicketDot();
        if (this.currentTicket && !this.currentTicket.x && !this.currentTicket.y && this.currentTicket.spaceUuid) this.unColorSpace();
        if (this.currentTicket && !this.currentTicket.spaceUuid && this.currentTicket.floorUuid) this.unColorFloor();
      }
      this.currentTicket = undefined;
    },
    unHoverTicketDot() {
      const device = document.getElementById(this.currentTicket.shortId);
      if (device) device.classList.remove('device--big');
    },
    unColorSpace() {
      const space = this.$palmier.getSpace(this.currentTicket.spaceUuid);
      if (space) space.style.fill = this.colors.silver;
    },
    unColorFloor() {
      const spaces = this.ticketFloor.next.map(s => s.uuid);
      const hoveredZone = this.$palmier.zone(spaces);
      hoveredZone.apply({
        style: s => (s.fill = this.colors.silver),
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.building-facts
  min-height auto
  height 100%
  .building-facts__center
    z-index 1
    padding-right 640px
    width 100%
    height 100%
    &--large
      padding-right 0
    @media screen and (max-width: 500px), (max-height: 500px)
      overflow hidden
      padding-right 0
  .viewer
    position relative
    padding 0.1px
    height 100%
    background-color $silver
    .tickets-plan-container
      display flex
      flex-direction column
      padding-top $space-2
      width 100%
      height 100%
      .nav-info
        margin 0 $space-2
    .tickets-stats
      position absolute
      top 15px
      left 50%
      margin-left -200px
      width 400px
    .back-button-arrow
      transform rotate(90deg)
    .building-name
      font-weight $fw-bold
  .building-list, .building-viewer
    z-index 1
    height 100%
    @media screen and (max-width: 500px)
      -webkit-overflow-scrolling touch
      -webkit-transform translateZ(0px)
      -webkit-transform translate3d(0, 0, 0)
      -webkit-perspective 1000
  .sidebar-out
    z-index 2
    .ticket-view
      height 100%
      .back-button-arrow
        padding-top 10px
        flex-shrink 0
        margin $space-2
        cursor pointer
        transition all 100ms ease
        transform rotate(90deg)
</style>
