<template>
<div class="sidebar-main">
    <span class="sidebar-title">
      {{ mainTitle }}
      <CrossPicto class="close-cross"
                  size="25px"
                  color="black"
                  @click.native="$emit('exitEdition')" />
    </span>
    <TicketCreateEditForm
      class="ticket-form"
      :ticketPosition="ticketPosition"
      :currentTicket="currentTicket"
       @exitEdition="$emit('exitEdition')"
    />
</div>
</template>

<script>
import CrossPicto from '@/app/components/ui/pictos/cross.vue';
import TicketCreateEditForm from '@/app/pages/ticket/components/ticket-create-edit-form.vue';

export default {
  name: 'TicketCreationEdition',
  components: {
    CrossPicto,
    TicketCreateEditForm,
  },
  props: {
    ticketPosition: {
      type: Object,
      default: () => {},
    },
    currentTicket: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      mainTitle: '',
    };
  },
  mounted() {
    this.mainTitle = (this.currentTicket && this.currentTicket.shortId) ? this.$t('pages.ticket.creationEdition.editTitle') : this.$t('pages.ticket.creationEdition.createTitle');
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$input-bg-color = $light

.sidebar-main
  display flex
  flex-direction column
  height 100%
  .sidebar-title
    padding 15px
    display inline-flex
    align-items center
    margin-bottom $space-2
    width 100%
    color $font-color
    text-transform uppercase
    font-weight 700
    font-size 2rem
    .close-cross
      top 10px
      right 10px
      margin-left auto
      cursor pointer
      transition all 100ms ease
      &:hover
        transition all 100ms ease
        transform scale(1.3)
  .ticket-form
    overflow-y auto

</style>
