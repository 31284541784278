<template>
  <div :class="buildingCheckbarClass"
       class="building-checkbar o-button o-button--shadows o-button--hover">
    <span class="building-checkbar__status">
      <span :class="checkCircleClass"
            class="check-circle"
            @click="emitMainClick" />
    </span>
    <span class="building-name"
          @click="emitMainClick">
      {{ building.name }}
    </span>
    <transition name="fade">
      <span v-if="!noSelection && selected"
            class="building-checkbar__selection"
            @click="emitSecondClick">
        {{ selection }}
      </span>
    </transition>
    <transition name="fade">
      <Drawer v-if="selected && openFloorSelection"
              posY="34px"
              side="left"
              class="drawer">
        <FloorSelection :floors="building.next"
                        :buildingUuid="building.uuid" />
      </Drawer>
    </transition>
  </div>
</template>

<script>
import Drawer from '@/app/components/ui/drawer.vue';
import FloorSelection from '@/app/components/floors/floor-selection.vue';

export default {
  name: 'BuildingCheckbar',
  components: {
    Drawer,
    FloorSelection,
  },
  props: {
    building: {
      type: Object,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: String,
      default: 'ALL',
    },
    openFloorSelection: {
      type: Boolean,
      default: false,
    },
    noSelection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkCircleClass() {
      return {
        'check-circle--selected': this.selected,
      };
    },
    buildingCheckbarClass() {
      return {
        'building-checkbar--selected': this.selected,
      };
    },
  },
  methods: {
    emitMainClick() {
      this.$emit('mainClick');
    },
    emitSecondClick() {
      this.$emit('secondClick');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.building-checkbar
  position relative
  display flex
  background-color white
  &--selected
    background-color $dark
    color white
  .building-checkbar__status, .building-name
    padding 4px 0
  .building-checkbar__status
    display flex
    justify-content center
    align-items center
    width 25px
  .building-name
    flex 3
  .building-checkbar__selection
    display flex
    justify-content center
    align-items center
    width 20%
    border-top-right-radius $border-radius
    border-bottom-right-radius $border-radius
    background-color black
    color white
    font-weight 400
    transition all 0.3s ease

.drawer
  position absolute
  top -38px
  left 105%
  transition all 0.3s ease

.check-circle
  width 10px
  height @width
  border-radius 50%
  background-color $silver
  transition all 0.3s ease
  &--selected
    background-color white

.o-button
  padding 0
  box-shadow $shadow-elevation-1
  @media screen and (max-width: 500px)
    width 140px

// .building-checkbar__selection and .drawer transition
.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
